/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCt9jmqIFc0gCa_j8AonwrJ_9n7ofiZFQE",
  "appId": "1:632562116652:web:9413e6e5d8e7cf16b18989",
  "authDomain": "schooldog-i-towns-ga.firebaseapp.com",
  "measurementId": "G-KJMQ8CM1WY",
  "messagingSenderId": "632562116652",
  "project": "schooldog-i-towns-ga",
  "projectId": "schooldog-i-towns-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-towns-ga.appspot.com"
}
